<template>
  <div>
    <el-page-header @back="$emit('goBack')">
      <span slot="content" class="fwb">待处理方案</span>
    </el-page-header>
    <el-divider></el-divider>
    <el-card style="position: relative;width: 80%;margin:0 auto;margin-bottom: 10px;" shadow="never">
      <div class="i-title">
        <h3><i class="mr-1"></i>产品信息</h3>
      </div>
      <div v-for="(item, index) in dataForm.productList"
           :key="index" class="list">
        <el-card shadow="never" class="card">
          <el-row class="i-row">
            <el-col :span="24" :offset="1">
              <label>服务产品：</label><span>{{ item.skuName }}</span>
            </el-col>
          </el-row>
          <el-row class="i-row">
            <el-col :span="7" :offset="1">
              <label>产品审核状态：</label><span><el-tag :type="tagType[item.audit]">{{ auditDict[item.audit] }}</el-tag></span>
            </el-col>
          </el-row>
          <el-row class="i-row">
            <el-col :span="7" :offset="1">
              <label>业务办理地区：</label><span>{{ item.orgName }}</span>
            </el-col>
          </el-row>
          <el-row class="i-row">
            <el-col :span="12" :offset="1">
              <label>产品报价：</label><span>{{ item.price }}</span>
            </el-col>
            <el-col :span="12" :offset="1">
              <label>产品数量：</label><span>{{ item.num }}</span>
            </el-col>
          </el-row>
<!--          <el-row class="i-row">-->
<!--            <el-col :span="5" :offset="1">-->
<!--              <label>首期：</label><span>{{ item.phaseOne }}</span>-->
<!--            </el-col>-->
<!--            <el-col :span="5" :offset="1">-->
<!--              <label>二期：</label><span>{{ item.phaseTwo }}</span>-->
<!--            </el-col>-->
<!--            <el-col :span="5" :offset="1">-->
<!--              <label>三期：</label><span>{{ item.phaseThree }}</span>-->
<!--            </el-col>-->
<!--            <el-col :span="5" :offset="1">-->
<!--              <label>四期：</label><span>{{ item.phaseFour }}</span>-->
<!--            </el-col>-->
<!--          </el-row>-->
        </el-card>
      </div>
      <el-divider></el-divider>
      <div class="i-title">
        <h3><i class="mr-1"></i>产品总计</h3>
      </div>
      <el-card shadow="never">
        <el-row class="i-row">
          <el-col  :span="7" :offset="1">
            <label>总金额：</label><span>{{ dataForm.totalSum }}</span>
          </el-col>
        </el-row>
        <el-row class="i-row">
          <el-col  :span="7" :offset="1">
            <label>方案状态：</label><span><el-tag :type="solutionType[dataForm.status]">{{ dataForm.statusName }}</el-tag></span>
          </el-col>
        </el-row>
        <el-row class="i-row">
          <el-col  :span="7" :offset="1">
            <label>办理公司：</label><span>{{ dataForm.company }}</span>
          </el-col>
        </el-row>
        <el-row class="i-row">
          <el-col  :span="7" :offset="1">
            <label>备注：</label><span>{{ dataForm.note }}</span>
          </el-col>
        </el-row>
        <el-row class="i-row">
          <el-col :span="7" :offset="1">
            <label>创建时间：</label><span>{{ dataForm.createTime }}</span>
          </el-col>
          <el-col :span="7" :offset="1">
            <label>创建人：</label><span>{{ dataForm.creatorName }}</span>
          </el-col>
        </el-row>
      </el-card>
      <div v-if="this.mode === 'dqr'">
        <el-divider></el-divider>
        <div class="i-title">
          <h3><i class="mr-1"></i>添加发票信息
            <el-button type="primary" style="float: right;margin-right: 10px" @click="invoiceClose"
                       v-if="dataForm.invoiceApplyStatus === '2'">不开发票
            </el-button>
            <el-button type="primary" style="float: right;margin-right: 10px" @click="invoiceUpdate">修改</el-button>
          </h3>
        </div>
        <el-card shadow="never">
          <div>
            <div v-if="dataForm.invoiceApplyStatus === '1'">
              <el-row class="i-row">
                <el-col  :span="7" :offset="1">
                  <label>发票类型：</label><span>不开发票</span>
                </el-col>
              </el-row>
            </div>
            <div v-else>
              <div v-if="invoiceInfo.invoiceType === '1'">
                <el-row class="i-row">
                  <el-col  :span="7" :offset="1">
                    <label>发票类型：</label><span>电子普通发票</span>
                    <el-tooltip style="font-size: 16px;margin-left: 5px;color: #409EFF" effect="dark" content="电子普通发票与纸质发票具有同等法律效力，可支持报销入账、商品售后凭证。"
                                placement="top-start">
                      <i class="el-icon-warning-outline cp"></i>
                    </el-tooltip>
                  </el-col>
                </el-row>
              </div>
              <div v-else>
                <el-row class="i-row">
                  <el-col  :span="7" :offset="1">
                    <label>发票类型：</label><span>增值税专用发票</span>
                  </el-col>
                </el-row>
              </div>
              <el-row class="i-row">
                <el-col  :span="7" :offset="1">
                  <label>{{ invoiceInfo.invoiceHeaderType === 'OWN' ? '个人' : '单位' }}：</label><span>{{ invoiceInfo.headerName }}</span>
                </el-col>
                <el-col  :span="7" :offset="1" v-if="invoiceInfo.invoiceHeaderType === 'COMPANY'">
                  <label>税号：</label><span>{{ invoiceInfo.dutyParagraph }}</span>
                </el-col>
              </el-row>
            </div>

          </div>
          <!--        <el-row class="i-row">-->
          <!--          <el-col  :span="7" :offset="1">-->
          <!--            <label>总金额：</label><span>{{ dataForm.totalSum }}</span>-->
          <!--          </el-col>-->
          <!--        </el-row>-->
          <!--        <el-row class="i-row">-->
          <!--          <el-col  :span="7" :offset="1">-->
          <!--            <label>方案状态：</label><span><el-tag :type="solutionType[dataForm.status]">{{ dataForm.statusName }}</el-tag></span>-->
          <!--          </el-col>-->
          <!--        </el-row>-->
          <!--        <el-row class="i-row">-->
          <!--          <el-col  :span="7" :offset="1">-->
          <!--            <label>办理公司：</label><span>{{ dataForm.company }}</span>-->
          <!--          </el-col>-->
          <!--        </el-row>-->
          <!--        <el-row class="i-row">-->
          <!--          <el-col  :span="7" :offset="1">-->
          <!--            <label>备注：</label><span>{{ dataForm.note }}</span>-->
          <!--          </el-col>-->
          <!--        </el-row>-->
          <!--        <el-row class="i-row">-->
          <!--          <el-col :span="7" :offset="1">-->
          <!--            <label>创建时间：</label><span>{{ dataForm.createTime }}</span>-->
          <!--          </el-col>-->
          <!--          <el-col :span="7" :offset="1">-->
          <!--            <label>创建人：</label><span>{{ dataForm.creatorName }}</span>-->
          <!--          </el-col>-->
          <!--        </el-row>-->
        </el-card>
      </div>

<!--      <el-divider></el-divider>-->
<!--      <div class="i-title">-->
<!--        <h3><i class="mr-1"></i>付款方式</h3>-->
<!--      </div>-->
<!--      <el-card shadow="never">-->
<!--        <el-row class="i-row">-->
<!--          <el-col :span="5" :offset="1">-->
<!--            <label>首期：</label><span>{{ dataForm.phaseOne }}</span>-->
<!--          </el-col>-->
<!--          <el-col :span="5" :offset="1">-->
<!--            <label>二期：</label><span>{{ dataForm.phaseTwo }}</span>-->
<!--          </el-col>-->
<!--          <el-col :span="5" :offset="1">-->
<!--            <label>三期：</label><span>{{ dataForm.phaseThree }}</span>-->
<!--          </el-col>-->
<!--          <el-col :span="5" :offset="1">-->
<!--            <label>四期：</label><span>{{ dataForm.phaseFour }}</span>-->
<!--          </el-col>-->
<!--        </el-row>-->
<!--      </el-card>-->
    </el-card>
    <el-dialog
            :close-on-click-modal="false"
            :show-close="false"
            :visible.sync="dialogVisible"
            width="25%"
            title="成单提示"
            :close-on-press-escape="false">
      <span>您的方案已成单，请前往支付{{this.time}}...</span>
      <span slot="footer" class="dialog-footer">
    <el-button type="success" @click="goOrder">我已知晓</el-button>
  </span>
    </el-dialog>
    <div style="text-align: center">
      <el-button type="primary" @click="agree" v-if="this.mode === 'dqr'">通过</el-button>
      <el-button type="danger" @click="reject" v-if="this.mode === 'dqr'">驳回</el-button>
    </div>
    <!--    发票信息编辑-->
    <invoiceEditing v-if="invoiceEditingVisible" ref="invoiceEditingRef"
                    @invoiceCallBack="invoiceCallBack"></invoiceEditing>
  </div>
</template>


<script>
  import invoiceEditing from '../../../service/component/invoice-editing'
  export default {
    name: 'item-product',
    components: {
      invoiceEditing
    },
    data () {
      return {
        mode: 'dqr',
        visible: false,
        loading: false,
        dialogVisible: false,
        dataForm: {
          id: '',
          productList: [],
          //  是否开具发票
          invoiceApplyStatus: '1'
        },
        dataList: [],
        auditDict: {},
        pageIndex: 1,
        pageSize: 10,
        totalPage: 0,
        dataListLoading: false,
        time: '',
        timer: null,
        tagType: {0: '', 1: 'success', 2: 'danger'},
        solutionType: {dqr: 'success', ywc: 'success', yzf: 'info', ybh: 'danger'},
        // 发票编辑弹框显隐
        invoiceEditingVisible: false,
        invoiceInfo: {}
      }
    },
    created () {
      this.$getDictMap('AUDIT_STATUS', (dict) => {
        this.auditDict = dict.AUDIT_STATUS
      })
    },
    methods: {
      init (id, mode) {
        this.visible = true
        this.mode = mode
        this.getSolutionInfo(this.$deepClone(id))
      },
      getSolutionInfo (id) {
        this.dataListLoading = true
        this.$http({
          url: this.$http.adornUrl(this.$api.SOLUTION.INFO + '/' + id),
          method: 'get'
        }).then(({data}) => {
          if (data && data.code === 0) {
            this.dataForm = data.info
            this.dataForm.invoiceApplyStatus = '1'
          }
          this.dataListLoading = false
        })
      },
      agree () {
        this.$http({
          url: this.$http.adornUrl(this.$api.SOLUTION.AGREE),
          method: 'post',
          params: this.$http.adornParams({
            'id': this.dataForm.id,
            'invoiceApplyStatus':this.dataForm.invoiceApplyStatus,
            'serviceOrderInvoiceEntity':this.dataForm.serviceOrderInvoiceEntity
          })
        }).then(({data}) => {
          if (data && data.code === 0) {
            this.dialogVisible = true
            this.countdown(5)
          }
          this.dataListLoading = false
        })
      },
      reject () {
        this.$http({
          url: this.$http.adornUrl(this.$api.SOLUTION.REJECT),
          method: 'post',
          data: this.$http.adornData(this.dataForm)
        }).then(({data}) => {
          if (data && data.code === 0) {
            this.$message.info('方案已驳回')
            this.$emit('goBack', true)
          }
          this.dataListLoading = false
        })
      },
      countdown (time) {
        if (time < 1) {
          this.dialogVisible = false
          this.time = '（0）'
          clearTimeout(this.timer)
          this.$router.push({name : 'my-order'})
          return
        }
        this.time = '（'+time+'）'
        this.timer = setTimeout(this.countdown, 1000, --time)
      },
      goOrder () {
        clearTimeout(this.timer)
        this.dialogVisible = false
        this.$router.push({name : 'my-order'})
      },
      // 发票编辑弹框
      invoiceUpdate() {
        this.invoiceEditingVisible = true
        this.$nextTick(() => {
          this.$refs.invoiceEditingRef.init(this.invoiceInfo)
        })
      },
      // 不开发票
      invoiceClose() {
        this.dataForm.invoiceApplyStatus = '1'
        this.$delete(this.dataForm, 'serviceOrderInvoiceEntity')
      },
      // 发票编辑回调
      invoiceCallBack(data) {
        this.invoiceEditingVisible = false
        if (data && data.headerName) {
          this.dataForm.invoiceApplyStatus = '2'
          this.dataForm.serviceOrderInvoiceEntity = data || {}
          this.invoiceInfo = this.$deepClone(data)
        }
      }
    }
  }
</script>

<style scoped>
  .i-row {
    display: flex;
    /*height: 36px;*/
    line-height: 36px;
    color: #303133;
  }
  .i-row div+div:not(:first-child) {
    margin-left: 4rem;
  }

  .i-title h3 {
    margin: 7px 0 12px;
    font-weight: bold;
    font-size: 17px;
    color: #349bde;
    padding-bottom: 12px;
    border-bottom: 1px dashed #E4E7ED;
  }

  .i-row label {
    font-weight: bold;
    color: #1a2633;
  }

  .agree {
    font-weight: bold;
    font-size: 17px;
    color: #349bde;
  }

  .reject {
    font-weight: bold;
    font-size: 17px;
    color: #b04861;
  }
  /* 奇数行的样式 */
  .list:nth-child(even)>.card {
    background: #f8fbfd;
  }
</style>
